import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PocketBase from 'pocketbase';
import { CheckCircleIcon, ClipboardDocumentIcon, UserIcon } from '@heroicons/react/24/outline';


const pb = new PocketBase('https://pocketbase.fullbread.ru/');

const Example = () => {
    const [publicServerData, setPublicServerData] = useState({
        name: 'Loading...',
        map: 'Loading...',
        players: 'Loading...',
        max_players: 'Loading...',
    });

    const [moddedServerData, setModdedServerData] = useState({
        name: 'Loading...',
        map: 'Loading...',
        players: 'Loading...',
        max_players: 'Loading...',
    });

    const [hardcoreServerData, setHardcoreServerData] = useState({
        name: 'Loading...',
        map: 'Loading...',
        players: 'Loading...',
        max_players: 'Loading...',
    });

    const [maps, setMaps] = useState([]);
    const [error, setError] = useState(null);
    const [isCopiedPublic, setIsCopiedPublic] = useState(false);
    const [isCopiedModded, setIsCopiedModded] = useState(false);
    const [isCopiedHardcore, setIsCopiedHardcore] = useState(false);
    const [ipPublic] = useState('193.164.17.72:27102');
    const [ipModded] = useState('193.164.17.72:27202');
    const [ipHard] = useState('193.164.17.72:27302');
    const [results, setResults] = useState([]);
    const [avatars, setAvatars] = useState({});

    const getStats = async () => {
        try {
            const response = await pb.collection('stats_view').getList(1, 100, {
                sort: '-date_time'
            });
            console.log(response);
            // Создаем объект для хранения уникальных platform_id
            // const uniqueResults = response.items.reduce((acc, current) => {
            //     const x = acc.find(item => item.platform_id === current.platform_id);
            //     if (!x) {
            //         acc.push(current);
            //     }
            //     return acc;
            // }, []);

            setResults(response.items);



        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const fetchAvatars = async () => {
            for (const player of results) {
                if (player.platform_id.startsWith('SteamNWI:')) {
                    const steamData = await getSteamData(player.platform_id);
                    if (steamData) {
                        setAvatars((prevAvatars) => ({
                            ...prevAvatars,
                            [player.platform_id]: {
                                avatarUrl: steamData.avatarmedium,
                                profileUrl: steamData.profileurl,
                            },
                        }));
                    }
                }
            }
        };
        fetchAvatars();
    }, [results]);


    useEffect(() => {
        const fetchPublicServerData = async () => {
            try {
                const response = await axios.get('https://request.fullbread.ru/server-info/public', {
                    headers: {
                        'Cache-Control': 'no-cache'
                    }
                });
                const data = response.data;

                setPublicServerData({
                    name: data.name,
                    map: data.map,
                    players: data.players,
                    max_players: data.max_players,
                });
            } catch (err) {
                console.error('Error fetching public server data:', err);
                setPublicServerData({
                    name: 'Error loading data',
                    map: 'Error loading data',
                    players: 'Error loading data',
                    max_players: 'Error loading data',
                });
            }
        };

        const fetchModdedServerData = async () => {
            setTimeout(async () => {
                try {
                    const response = await axios.get('https://request.fullbread.ru/server-info/modded', {
                        headers: {
                            'Cache-Control': 'no-cache'
                        }
                    });
                    const data = response.data;

                    setModdedServerData({
                        name: data.name,
                        map: data.map,
                        players: data.players,
                        max_players: data.max_players,
                    });
                } catch (err) {
                    console.error('Error fetching modded server data:', err);
                    setModdedServerData({
                        name: 'Error loading data',
                        map: 'Error loading data',
                        players: 'Error loading data',
                        max_players: 'Error loading data',
                    });
                }
            }, 500);
        };

        const fetchHardcoreServerData = async () => {
            setTimeout(async () => {
                try {
                    const response = await axios.get('https://request.fullbread.ru/server-info/hardcore', {
                        headers: {
                            'Cache-Control': 'no-cache'
                        }
                    });
                    const data = response.data;

                    setHardcoreServerData({
                        name: data.name,
                        map: data.map,
                        players: data.players,
                        max_players: data.max_players,
                    });
                } catch (err) {
                    console.error('Error fetching hardcore server data:', err);
                    setHardcoreServerData({
                        name: 'Error loading data',
                        map: 'Error loading data',
                        players: 'Error loading data',
                        max_players: 'Error loading data',
                    });
                }
            }, 500);
        };

        const fetchMaps = async () => {
            try {
                const result = await pb.collection('get_maps').getList(1, 50);
                setMaps(result.items);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchPublicServerData();
        setTimeout(() => {
            fetchModdedServerData();
        }, 100);
        setTimeout(() => {
            fetchHardcoreServerData();
        }, 200);
        setTimeout(() => {
            fetchMaps();
        }, 300);
        setTimeout(() => {
            getStats();
        }, 500);



    }, []);

    const handleCopyToClipboard = async (ip) => {
        try {
            await navigator.clipboard.writeText(ip);
            setIsCopiedPublic(true);
            setTimeout(() => {
                setIsCopiedPublic(false);
            }, 2000);
        } catch (error) {
            console.error('Ошибка копирования в буфер обмена:', error);
        }
    };

    const handleCopyToClipboardModded = async (ip) => {
        try {
            await navigator.clipboard.writeText(ip);
            setIsCopiedModded(true);
            setTimeout(() => {
                setIsCopiedModded(false);
            }, 2000);
        } catch (error) {
            console.error('Ошибка копирования в буфер обмена:', error);
        }
    };

    const handleCopyToClipboardHardcore = async (ip) => {
        try {
            await navigator.clipboard.writeText(ip);
            setIsCopiedHardcore(true);
            setTimeout(() => {
                setIsCopiedHardcore(false);
            }, 2000);
        } catch (error) {
            console.error('Ошибка копирования в буфер обмена:', error);
        }
    };
    const getSteamData = async (steamID) => {
        try {
            const response = await axios.post(
                'https://steamdata.fullbread.ru/steam-users',
                { steamIds: [steamID.split(':')[1]] }
            );

            const playerData = response.data[0];
            return playerData;
        } catch (error) {
            console.error('Error fetching Steam data:', error);
            return null;
        }
    };
    const formatDateTime = (dateString: string) => {
        const date = new Date(dateString);

        // Создаём словарь для сокращённых названий месяцев
        const shortMonthNames = [
            'янв.', 'февр.', 'март', 'апр.', 'май', 'июнь',
            'июль', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'
        ];

        const day = date.getUTCDate(); // День месяца (UTC)
        const month = shortMonthNames[date.getUTCMonth()]; // Получаем сокращённое название месяца
        const hours = String(date.getUTCHours()).padStart(2, '0'); // Часы (UTC)
        const minutes = String(date.getUTCMinutes()).padStart(2, '0'); // Минуты (UTC)

        return `${day} ${month} ${hours}:${minutes}`; // Возвращаем строку в формате "2 окт. 16:20"
    };

    const getMapImagePath = (map) => {
        if (!map) {
            return '';
        }

        const collectionId = map.collectionId;
        const recordId = map.id;
        const fileName = map.preview;

        return `https://pocketbase.fullbread.ru/api/files/${collectionId}/${recordId}/${fileName}`;
    };

    const findMapByCode = (mapCode) => {
        return maps.find((map) => map.code === mapCode);
    };

    return (
        <section className="body-font bg-darker-light">
            <div className="container mx-auto flex flex-wrap">


                <div className="mb-8">
                    <h1 className="container mx-auto flex px-5 pt-12 md:flex-row flex-col items-center title-font text-3xl mb-0 font-medium text-white">Статус серверов</h1>

                    {/* Блок с публичным сервером */}
                    <div className="flex px-5 pt-8 md:flex-row flex-col items-center  max-w-4xl">
                        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center md:w-full">
                            <p className="font-semibold    text-white w-full">Название: <span className="font-light">{publicServerData.name}</span> </p>
                            <p className="font-semibold    text-white w-full">Карта: <span className="font-light">{findMapByCode(publicServerData.map)?.name ?? publicServerData.map}</span></p>
                            <p className="font-semibold    text-white w-full mb-8">Режим: <span className="font-light">{findMapByCode(publicServerData.map)?.mode ?? ''}</span></p>

                            {/* Кнопка копирования IP */}
                            <div className="flex flex-col sm:flex-row items-center mb-4">
                                <input
                                    type="text"
                                    readOnly
                                    className="border rounded-lg p-2 bg-white text-gray-700 w-full sm:w-auto text-center mb-2 sm:mb-0"
                                    value={ipPublic}
                                />
                                <button
                                    className="bg-gray-100 inline-flex py-2 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none sm:ml-2"
                                    onClick={() => handleCopyToClipboard(ipPublic)}
                                >
                                    {isCopiedPublic ? (
                                        <>
                                            <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                            <span className="ml-4 text-green-500">Скопировано!</span>
                                        </>
                                    ) : (
                                        <>
                                            <ClipboardDocumentIcon className="w-6 h-6" />
                                            <span className="ml-4">Скопировать</span>
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>

                        {/* Изображение карты для публичного сервера */}
                        <div className="lg:max-w-xs lg:w-full md:w-full w-5/6">
                            <img
                                className="object-cover object-center rounded"
                                src={getMapImagePath(findMapByCode(publicServerData.map))}
                                alt={findMapByCode(publicServerData.map)?.name ?? ''}
                            />
                        </div>
                    </div>

                    {/* Блок с хардкорным сервером */}
                    <div className="flex px-5 pt-16 md:flex-row flex-col items-center  max-w-4xl">
                        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center md:w-full">
                            <p className="font-semibold   text-white w-full">Название: <span className="font-light">{hardcoreServerData.name} </span> </p>
                            <p className="font-semibold   text-white w-full">Карта: <span className="font-light">{findMapByCode(hardcoreServerData.map)?.name ?? hardcoreServerData.map}</span></p>
                            <p className="font-semibold    text-white w-full mb-8">Режим: <span className="font-light">{findMapByCode(hardcoreServerData.map)?.mode ?? ''}</span></p>

                            {/* Кнопка копирования IP */}
                            <div className="flex flex-col sm:flex-row items-center mb-4">
                                <input
                                    type="text"
                                    readOnly
                                    className="border rounded-lg p-2 bg-white text-gray-700 w-full sm:w-auto text-center mb-2 sm:mb-0"
                                    value={ipHard}
                                />
                                <button
                                    className="bg-gray-100 inline-flex py-2 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none sm:ml-2"
                                    onClick={() => handleCopyToClipboardHardcore(ipHard)}
                                >
                                    {isCopiedHardcore ? (
                                        <>
                                            <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                            <span className="ml-4 text-green-500">Скопировано!</span>
                                        </>
                                    ) : (
                                        <>
                                            <ClipboardDocumentIcon className="w-6 h-6" />
                                            <span className="ml-4">Скопировать</span>
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>

                        {/* Изображение карты для модифицированного сервера */}
                        <div className="lg:max-w-xs lg:w-full md:w-full w-5/6">
                            <img
                                className="object-cover object-center rounded"
                                src={getMapImagePath(findMapByCode(hardcoreServerData.map))}
                                alt={findMapByCode(hardcoreServerData.map)?.name ?? ''}
                            />
                        </div>
                    </div>

                    {/* Блок с модифицированным сервером */}
                    <div className="flex px-5 pt-16 md:flex-row flex-col items-center  max-w-4xl">
                        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center md:w-full">
                            <p className="font-semibold   text-white w-full">Название: <span className="font-light">{moddedServerData.name} </span> </p>
                            <p className="font-semibold   text-white w-full">Карта: <span className="font-light">{findMapByCode(moddedServerData.map)?.name ?? moddedServerData.map}</span></p>
                            <p className="font-semibold    text-white w-full mb-8">Режим: <span className="font-light">{findMapByCode(moddedServerData.map)?.mode ?? ''}</span></p>

                            {/* Кнопка копирования IP */}
                            <div className="flex flex-col sm:flex-row items-center mb-4">
                                <input
                                    type="text"
                                    readOnly
                                    className="border rounded-lg p-2 bg-white text-gray-700 w-full sm:w-auto text-center mb-2 sm:mb-0"
                                    value={ipModded}
                                />
                                <button
                                    className="bg-gray-100 inline-flex py-2 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none sm:ml-2"
                                    onClick={() => handleCopyToClipboardModded(ipModded)}
                                >
                                    {isCopiedModded ? (
                                        <>
                                            <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                            <span className="ml-4 text-green-500">Скопировано!</span>
                                        </>
                                    ) : (
                                        <>
                                            <ClipboardDocumentIcon className="w-6 h-6" />
                                            <span className="ml-4">Скопировать</span>
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>

                        {/* Изображение карты для модифицированного сервера */}
                        <div className="lg:max-w-xs lg:w-full md:w-full w-5/6">
                            <img
                                className="object-cover object-center rounded"
                                src={getMapImagePath(findMapByCode(moddedServerData.map))}
                                alt={findMapByCode(moddedServerData.map)?.name ?? ''}
                            />
                        </div>
                    </div>
                </div>
                {results.length >= 1 && (
                    <div className="mr-auto ml-4">

                        <div className="container flex pt-14 md:flex-row flex-col title-font text-xl mb-0 font-medium text-white">
                            Активность
                        </div>

                        <div className="mt-8 flow-root max-h-96  min-w-80 max-w-6xl overflow-y-auto bg-gray-800 rounded-lg scrollbar">

                            <ul role="list" >
                                {results.map((player, playerIdx) => (
                                    <li key={player.ID}>
                                        <div className="relative pb-2 pl-2 pr-2 pt-3">

                                            <div className="relative flex space-x-3 items-center">
                                                <div>
                                                    {avatars[player.platform_id] ? (
                                                        <a href={avatars[player.platform_id].profileUrl} target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src={avatars[player.platform_id].avatarUrl}
                                                                alt="Steam Avatar"
                                                                className="h-8 w-8 rounded-full"
                                                            />
                                                        </a>
                                                    ) : (
                                                        <UserIcon className="ml-1 h-5 w-5 text-white" aria-hidden="true" />

                                                    )}
                                                </div>
                                                <div className="flex min-w-0 flex-1 justify-between space-x-1 items-center">

                                                    <div className="text-sm">
                                                        {avatars[player.platform_id] ? (

                                                            <a href={avatars[player.platform_id].profileUrl} target="_blank" className="font-medium text-white">
                                                                {player.name}
                                                            </a>
                                                        ) : (
                                                            <a className="font-medium text-white">
                                                                {player.name}
                                                            </a>
                                                        )}

                                                    </div>

                                                    <div className="text-sm border rounded-xl p-1 bg-gray-200">
                                                        {player.server}
                                                    </div>

                                                    <div className="whitespace-nowrap text-right text-sm text-white">
                                                        {formatDateTime(player.date_time)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}

            </div>


        </section>
    );
};

export default Example;
